import { CheckOutlined, CloseOutlined, MinusOutlined } from "@ant-design/icons";
import React, { JSXElementConstructor } from "react";
import { ReactComponent as FreezeColumnIcon } from "assets/icons/common/arrow-left.svg";
import { ReactComponent as CopyIcon } from "assets/icons/common/copy.svg";
import { ReactComponent as DragIcon } from "assets/icons/common/drag.svg";
import { ReactComponent as EyeIcon } from "assets/icons/common/eye.svg";
import { ReactComponent as HideIcon } from "assets/icons/common/hide.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/common/settings.svg";
import { ReactComponent as TrashIcon } from "assets/icons/common/trash.svg";
import { ReactComponent as BackIcon } from "legacy/assets/icons/control/back.svg";
import { ReactComponent as BoldFontIcon } from "legacy/assets/icons/control/bold.svg";
import { ReactComponent as BulletsIcon } from "legacy/assets/icons/control/bullets.svg";
import { ReactComponent as CenterAlignIcon } from "legacy/assets/icons/control/center-align.svg";
import { ReactComponent as CloseIcon } from "legacy/assets/icons/control/close.svg";
import { ReactComponent as CollapseIcon } from "legacy/assets/icons/control/collapse.svg";
import { ReactComponent as DecreaseIcon } from "legacy/assets/icons/control/decrease.svg";
import { ReactComponent as DraggableIcon } from "legacy/assets/icons/control/draggable.svg";
import { ReactComponent as EditWhiteIcon } from "legacy/assets/icons/control/edit-white.svg";
import { ReactComponent as EditIcon } from "legacy/assets/icons/control/edit.svg";
import { ReactComponent as HeadingOneIcon } from "legacy/assets/icons/control/heading_1.svg";
import { ReactComponent as HeadingTwoIcon } from "legacy/assets/icons/control/heading_2.svg";
import { ReactComponent as HeadingThreeIcon } from "legacy/assets/icons/control/heading_3.svg";
import { ReactComponent as HelpIcon } from "legacy/assets/icons/control/help.svg";
import { ReactComponent as IconLeftAlignIcon } from "legacy/assets/icons/control/icon-left-align.svg";
import { ReactComponent as IconRightAlignIcon } from "legacy/assets/icons/control/icon-right-align.svg";
import { ReactComponent as IncreaseIcon } from "legacy/assets/icons/control/increase.svg";
import { ReactComponent as ItalicsFontIcon } from "legacy/assets/icons/control/italics.svg";
import { ReactComponent as JsToggleIcon } from "legacy/assets/icons/control/js-toggle.svg";
import { ReactComponent as LaunchIcon } from "legacy/assets/icons/control/launch.svg";
import { ReactComponent as LeftAlignIcon } from "legacy/assets/icons/control/left-align.svg";
import { ReactComponent as MoreVerticalIcon } from "legacy/assets/icons/control/more-vertical.svg";
import { ReactComponent as MoveIcon } from "legacy/assets/icons/control/move.svg";
import { ReactComponent as ParagraphIcon } from "legacy/assets/icons/control/paragraph.svg";
import { ReactComponent as ParagraphTwoIcon } from "legacy/assets/icons/control/paragraph_2.svg";
import { ReactComponent as PickMyLocationSelectedIcon } from "legacy/assets/icons/control/pick-location-selected.svg";
import PlayIcon from "legacy/assets/icons/control/play-icon.png";
import { ReactComponent as RemoveIcon } from "legacy/assets/icons/control/remove.svg";
import { ReactComponent as RightAlignIcon } from "legacy/assets/icons/control/right-align.svg";
import { ReactComponent as SortIcon } from "legacy/assets/icons/control/sort-icon.svg";
import { ReactComponent as VerticalAlignAuto } from "legacy/assets/icons/control/vertical-align-auto.svg";
import { ReactComponent as VerticalAlignBottom } from "legacy/assets/icons/control/vertical_align_bottom.svg";
import { ReactComponent as VerticalAlignCenter } from "legacy/assets/icons/control/vertical_align_center.svg";
import { ReactComponent as VerticalAlignTop } from "legacy/assets/icons/control/vertical_align_top.svg";
import { ReactComponent as ViewIcon } from "legacy/assets/icons/control/view.svg";
import { ReactComponent as OverflowMenuIcon } from "legacy/assets/icons/menu/overflow-menu.svg";
import { IconProps, IconWrapper } from "legacy/constants/IconConstants";

export const ControlIcons: {
  [id: string]: JSXElementConstructor<IconProps>;
} = {
  DELETE_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <TrashIcon />
    </IconWrapper>
  ),
  MOVE_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <MoveIcon />
    </IconWrapper>
  ),
  EDIT_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <EditIcon />
    </IconWrapper>
  ),
  VIEW_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <ViewIcon />
    </IconWrapper>
  ),
  MORE_VERTICAL_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <MoreVerticalIcon />
    </IconWrapper>
  ),
  MORE_HORIZONTAL_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <OverflowMenuIcon />
    </IconWrapper>
  ),
  JS_TOGGLE: (props: IconProps) => (
    <IconWrapper {...props}>
      <JsToggleIcon />
    </IconWrapper>
  ),
  INCREASE_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <IncreaseIcon />
    </IconWrapper>
  ),
  DECREASE_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <DecreaseIcon />
    </IconWrapper>
  ),
  DRAGGABLE_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <DraggableIcon />
    </IconWrapper>
  ),
  CLOSE_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <CloseIcon />
    </IconWrapper>
  ),
  PICK_MY_LOCATION_SELECTED_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <PickMyLocationSelectedIcon />
    </IconWrapper>
  ),
  SETTINGS_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <SettingsIcon />
    </IconWrapper>
  ),
  HELP_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <HelpIcon />
    </IconWrapper>
  ),
  PLAY_VIDEO: (props: IconProps) => (
    <IconWrapper {...props}>
      <img
        src={PlayIcon}
        style={{ height: "30px", width: "30px" }}
        alt="Datasource"
      />
    </IconWrapper>
  ),
  REMOVE_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <RemoveIcon />
    </IconWrapper>
  ),
  DRAG_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <DragIcon />
    </IconWrapper>
  ),
  COLLAPSE_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <CollapseIcon />
    </IconWrapper>
  ),
  SORT_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <SortIcon />
    </IconWrapper>
  ),
  EDIT_WHITE: (props: IconProps) => (
    <IconWrapper {...props}>
      <EditWhiteIcon />
    </IconWrapper>
  ),
  LAUNCH_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <LaunchIcon />
    </IconWrapper>
  ),
  BACK_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <BackIcon />
    </IconWrapper>
  ),
  EYE_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <EyeIcon />
    </IconWrapper>
  ),
  HIDE_COLUMN: (props: IconProps) => (
    <IconWrapper {...props}>
      <HideIcon />
    </IconWrapper>
  ),
  DELETE_COLUMN: (props: IconProps) => (
    <IconWrapper {...props}>
      <TrashIcon />
    </IconWrapper>
  ),
  BOLD_FONT: (props: IconProps) => (
    <IconWrapper {...props}>
      <BoldFontIcon />
    </IconWrapper>
  ),
  ITALICS_FONT: (props: IconProps) => (
    <IconWrapper {...props}>
      <ItalicsFontIcon />
    </IconWrapper>
  ),
  LEFT_ALIGN: (props: IconProps) => (
    <IconWrapper {...props}>
      <LeftAlignIcon />
    </IconWrapper>
  ),
  ICON_LEFT_ALIGN: (props: IconProps) => (
    <IconWrapper {...props}>
      <IconLeftAlignIcon />
    </IconWrapper>
  ),
  CENTER_ALIGN: (props: IconProps) => (
    <IconWrapper {...props}>
      <CenterAlignIcon />
    </IconWrapper>
  ),
  RIGHT_ALIGN: (props: IconProps) => (
    <IconWrapper {...props}>
      <RightAlignIcon />
    </IconWrapper>
  ),
  ICON_RIGHT_ALIGN: (props: IconProps) => (
    <IconWrapper {...props}>
      <IconRightAlignIcon />
    </IconWrapper>
  ),
  VERTICAL_TOP: (props: IconProps) => (
    <IconWrapper {...props}>
      <VerticalAlignTop />
    </IconWrapper>
  ),
  VERTICAL_CENTER: (props: IconProps) => (
    <IconWrapper {...props}>
      <VerticalAlignCenter />
    </IconWrapper>
  ),
  VERTICAL_BOTTOM: (props: IconProps) => (
    <IconWrapper {...props}>
      <VerticalAlignBottom />
    </IconWrapper>
  ),
  VERTICAL_AUTO: (props: IconProps) => (
    <IconWrapper {...props}>
      <VerticalAlignAuto />
    </IconWrapper>
  ),
  COPY_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <CopyIcon />
    </IconWrapper>
  ),
  HEADING_ONE: (props: IconProps) => (
    <IconWrapper {...props}>
      <HeadingOneIcon />
    </IconWrapper>
  ),
  HEADING_TWO: (props: IconProps) => (
    <IconWrapper {...props}>
      <HeadingTwoIcon />
    </IconWrapper>
  ),
  HEADING_THREE: (props: IconProps) => (
    <IconWrapper {...props}>
      <HeadingThreeIcon />
    </IconWrapper>
  ),
  PARAGRAPH: (props: IconProps) => (
    <IconWrapper {...props}>
      <ParagraphIcon />
    </IconWrapper>
  ),
  PARAGRAPH_TWO: (props: IconProps) => (
    <IconWrapper {...props}>
      <ParagraphTwoIcon />
    </IconWrapper>
  ),
  BULLETS: (props: IconProps) => (
    <IconWrapper {...props}>
      <BulletsIcon />
    </IconWrapper>
  ),
  FREEZE_COLUMN: (props: IconProps) => (
    <IconWrapper {...props}>
      <FreezeColumnIcon />
    </IconWrapper>
  ),
  EMPTY: () => null,
  CHECK: (props: IconProps) => (
    <IconWrapper {...props}>
      <CheckOutlined />
    </IconWrapper>
  ),
  CLOSE: (props: IconProps) => (
    <IconWrapper {...props}>
      <CloseOutlined />
    </IconWrapper>
  ),
  MINUS: (props: IconProps) => (
    <IconWrapper {...props}>
      <MinusOutlined />
    </IconWrapper>
  ),
};

export type ControlIconName = keyof typeof ControlIcons;
