import { Classes } from "@blueprintjs/core";
import { ThemeMode } from "@superblocksteam/shared";
import React, { useMemo } from "react";
import PaddingOverlay, {
  useIsWidgetSelected,
} from "legacy/pages/Editor/CanvasArenas/PaddingOverlay";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { getSpacingIsFocused } from "legacy/selectors/propertyPaneSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { useAppSelector } from "store/helpers";

import { getIconSizeBasedOnFontSize } from "../typographyUtils";
import { getFontSizeInPxFromTextStyle } from "../typographyUtils";
import { KeyValuePropertyKey } from "./components/KeyValuePropertyKey";
import { KeyValuePropertyValue } from "./components/KeyValuePropertyValue";
import {
  CanvasSpacingClass,
  createCssVariables,
  DEFAULT_KEY_VALUE_WIDGET_VALUE_STYLE_VARIANT,
  KeyValueComponentClass,
  useEntryClassNames,
  useInputStyles,
  useLabelStyles,
  useLinkStyles,
} from "./styles";
import { KeyValueProperty, KeyValueComponentProps } from "./types";

export const KeyValueComponent = (props: KeyValueComponentProps) => {
  const theme = useAppSelector(selectGeneratedTheme);
  const appMode = useAppSelector(getAppMode);
  const widgetSelected = useIsWidgetSelected(props.widgetId);
  const spacingHovered = useAppSelector(getSpacingIsFocused);

  const cssVariableDeclarations = useMemo(
    () =>
      createCssVariables({
        theme,
        styleProps: props.styleProps,
        keyWidth: props.keyProps?.width,
      }),
    [theme, props.styleProps, props.keyProps?.width],
  );

  const keyStyles = useLabelStyles(props.keyProps);
  const valueStyles = useInputStyles(props.valueProps);
  const entryClassNames = useEntryClassNames(props.keyProps);

  const keyIconSize = getIconSizeBasedOnFontSize(
    getFontSizeInPxFromTextStyle({
      nestedProps: keyStyles.style,
      typographies: theme.typographies,
      textStyleVariant: keyStyles.textStyleVariant,
      defaultTextStyleVariant: DEFAULT_KEY_VALUE_WIDGET_VALUE_STYLE_VARIANT,
    }),
  );

  const valueIconSize = getIconSizeBasedOnFontSize(
    getFontSizeInPxFromTextStyle({
      nestedProps: valueStyles.style,
      typographies: theme.typographies,
      textStyleVariant: valueStyles.textStyleVariant,
      defaultTextStyleVariant: DEFAULT_KEY_VALUE_WIDGET_VALUE_STYLE_VARIANT,
    }),
  );

  const linkStyles = useLinkStyles(props.valueProps, theme);

  return (
    <div
      style={cssVariableDeclarations}
      className={`${KeyValueComponentClass} ${props.isLoading ? Classes.SKELETON : ""}`}
    >
      {appMode && (
        <PaddingOverlay
          widgetId={props.widgetId}
          padding={props.styleProps.padding ?? theme.keyValue.padding}
          appMode={appMode}
        />
      )}
      {props.propertiesOrder.map((key) => {
        const property: KeyValueProperty = props.properties[key];

        if (!property) {
          console.warn(
            `KeyValueComponent: Property ${key} not defined in widget properties`,
          );
          return null;
        }

        if (!property.isVisible) {
          return null;
        }

        return (
          <div key={key} className={entryClassNames}>
            <span
              className={`${CanvasSpacingClass} ${widgetSelected ? "selected" : ""}  ${spacingHovered ? "hovered" : ""}`}
              data-dark-theme={theme.mode === ThemeMode.DARK}
              data-edit-mode={appMode === APP_MODE.EDIT}
            />
            <KeyValuePropertyKey
              style={keyStyles.style}
              property={property}
              keyProps={props.keyProps}
              iconSize={keyIconSize}
            />
            <KeyValuePropertyValue
              valueStyles={valueStyles}
              property={property}
              data={props.sourceData ?? {}}
              runEventHandlers={props.runEventHandlers}
              valueProps={props.valueProps}
              iconSize={valueIconSize}
              componentWidth={props.componentWidth}
              componentHeight={props.componentHeight}
              linkStyles={linkStyles}
            />
          </div>
        );
      })}
    </div>
  );
};
