import { Dimension } from "@superblocksteam/shared";
import { get } from "lodash";
import { ControlLayout } from "legacy/components/propertyControls/BaseControl";
import { CurrencyList } from "legacy/constants/FormatConstants";
import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import {
  PropertyPopoverMaxHeight,
  WidgetLabelPosition,
} from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { GeneratedTheme } from "legacy/themes";
import {
  TIMEZONE_OPTIONS,
  DATE_OUTPUT_FORMATS,
  DATE_INPUT_FORMATS,
  NUMBER_FORMATTING_OPTIONS,
  DEFAULT_DATE_OUTPUT_FORMAT,
} from "legacy/utils/FormatUtils";
import { createPerCornerBorderRadius } from "pages/Editors/AppBuilder/Sidebar/BorderRadiusEditor";
import { getFirstPathSegments, getParentPath } from "utils/dottedPaths";
import { getComponentDimensions } from "utils/size";
import { BUTTON_STYLE_OPTIONS } from "../ButtonWidget/constants";
import { THEME_STYLE_DEFAULTS } from "../ButtonWidget/constants";
import { labelWidthProperty } from "../FormInputs/formInputsProperties";
import { BooleanStyleFalse, ImageSize } from "../Shared/ValueWithTypeUtils";
import {
  iconPositionProperty,
  iconProperty,
  maximumFractionDigitsProperty,
  minimumFractionDigitsProperty,
} from "../appearanceProperties";
import {
  labelPositionProperty,
  paddingProperty,
  visibleProperties,
  sizeSection,
  spacingProperty,
} from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { styleProperties, typographyProperties } from "../styleProperties";
import {
  DEFAULT_BORDER_OBJECT,
  KEY_VALUE_WIDGET_PROPERTY_TYPES,
} from "./constants";
import {
  DEFAULT_KEY_VALUE_WIDGET_KEY_STYLE_VARIANT,
  DEFAULT_KEY_VALUE_WIDGET_VALUE_STYLE_VARIANT,
} from "./styles";
import { PropertyType, type KeyValueWidgetProps } from "./types";
import { toReadableKey } from "./utils";
import type { WidgetPropsRuntime } from "../BaseWidget";
import type { WidgetLayoutProps } from "../shared";

const hideForTypes = (types: PropertyType[]) => {
  return (props: KeyValueWidgetProps, propertyPath: string) => {
    const type = get(
      props,
      `${getFirstPathSegments(propertyPath, 2)}.type`,
      "",
    );
    return types.includes(type);
  };
};

const showForTypes = (types: PropertyType[]) => {
  return (props: KeyValueWidgetProps, propertyPath: string) => {
    const type = get(
      props,
      `${getFirstPathSegments(propertyPath, 2)}.type`,
      "",
    );
    return !types.includes(type);
  };
};

const config: PropertyPaneCategoryConfig<KeyValueWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      helpText: "The object to be displayed",
      propertyName: "sourceData",
      label: "Source data",
      controlType: "INPUT_JS_EXPR",
      placeholderText: 'Enter { "col1": "val1" }',
      inputType: "OBJECT",
      forceVertical: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      helpText: "Properties to display",
      propertyName: "properties",
      controlType: "KEY_VALUE_PROPERTIES",
      label: "Key value pairs",
      isBindProperty: false,
      isTriggerProperty: false,
      headerControlType: "ADD_KEYVALUE_PROPERTY",
      panelConfig: {
        editableTitle: false,
        titlePropertyName: "key",
        panelIdPropertyName: "id",
        showEditIcon: false,
        adaptiveHeight: { maxHeight: PropertyPopoverMaxHeight },
        children: [
          {
            sectionName: "Property Control",
            sectionCategory: PropsPanelCategory.Content,
            children: [
              {
                propertyName: "key",
                helpText: "The key of the key-value pair",
                label: "Key",
                controlType: "INPUT_TEXT",
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const baseProperty = getParentPath(propertyPath);
                  const isDerived = get(
                    props,
                    `${baseProperty}.isDerived`,
                    false,
                  );
                  return !isDerived;
                },
              },
              {
                propertyName: "label",
                helpText: "The label displayed for the key-value pair",
                label: "Displayed key",
                controlType: "COMPUTE_KEY_VALUE_PROPERTY",
                isBindProperty: true,
                isTriggerProperty: false,
                isRemovable: true,
                visibility: "SHOW_NAME",
                editorArguments: ["key", "value"],
                defaultValueFn({ props, propertyName }) {
                  const baseProperty = getParentPath(propertyName);
                  const key = get(props, `${baseProperty}.key`, "");
                  return toReadableKey(key);
                },
              },
              {
                propertyName: "computedValue",
                helpText: "The value for the key value pair",
                label: "Value",
                controlType: "COMPUTE_KEY_VALUE_PROPERTY",
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const baseProperty = getParentPath(propertyPath);
                  const isDerived = get(
                    props,
                    `${baseProperty}.isDerived`,
                    false,
                  );
                  return !isDerived;
                },
                isBindProperty: true,
                editorArguments: ["key"],
                isTriggerProperty: false,
                isRemovable: true,
                visibility: "SHOW_NAME",
                defaultValue: "",
              },
              {
                propertyName: "type",
                helpText: "The data type for the value",
                label: "Data type",
                controlType: "DROP_DOWN",
                options: KEY_VALUE_WIDGET_PROPERTY_TYPES,
                isBindProperty: false,
                isTriggerProperty: false,
                updateHook: ({ props, propertyPath, propertyValue }) => {
                  const baseProperty = getParentPath(propertyPath);
                  const previousType = get(props, `${baseProperty}.type`, "");
                  const previousIcon = get(
                    props,
                    `${baseProperty}.valueProps.icon`,
                    "",
                  );

                  if (propertyValue === PropertyType.EMAIL) {
                    return [
                      {
                        propertyPath: `${baseProperty}.valueProps.icon`,
                        propertyValue: "{{ icons.mail }}",
                      },
                    ];
                  } else if (
                    previousType === PropertyType.EMAIL &&
                    previousIcon === "{{ icons.mail }}"
                  ) {
                    return [
                      {
                        propertyPath: `${baseProperty}.valueProps.icon`,
                        propertyValue: undefined,
                      },
                    ];
                  }
                },
              },
              {
                propertyName: "displayedValue",
                helpText: "Customize the value that’s displayed",
                label: "Displayed value",
                controlType: "COMPUTE_KEY_VALUE_PROPERTY",
                isBindProperty: true,
                isTriggerProperty: false,
                visibility: "SHOW_NAME",
                isRemovable: true,
                defaultValue: "{{value}}",
                editorArguments: ["key", "value"],
                hidden: showForTypes([PropertyType.TEXT]),
              },
              // button properties
              {
                propertyName: "typeSpecificProps.buttonLabel",
                label: "Button label",
                controlType: "COMPUTE_KEY_VALUE_PROPERTY",
                editorArguments: ["key", "value"],
                isBindProperty: true,
                isTriggerProperty: false,
                defaultValue: "Click me",
                hidden: showForTypes([PropertyType.BUTTON]),
              },
              {
                propertyName: "typeSpecificProps.isDisabled",
                label: "Disabled",
                helpText: "Disables user interaction with this component",
                controlType: "SWITCH",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: VALIDATION_TYPES.BOOLEAN,
                hidden: showForTypes([PropertyType.BUTTON]),
              },

              // number and currency properties
              {
                propertyName: "typeSpecificProps.currency",
                helpText: "The three letter ISO 4217 currency code",
                label: "Currency code",
                controlType: "DROP_DOWN",
                defaultValue: "USD",
                options: CurrencyList.map((currency) => ({
                  label: currency,
                  value: currency,
                })),
                hidden: showForTypes([PropertyType.CURRENCY]),
                isBindProperty: true,
                isJSConvertible: true,
                isTriggerProperty: false,
              },
              {
                propertyName: "typeSpecificProps.notation",
                helpText: "The display format of the number",
                label: "Number format",
                controlType: "DROP_DOWN",
                defaultValue: "standard",
                options: NUMBER_FORMATTING_OPTIONS,
                hidden: showForTypes([
                  PropertyType.NUMBER,
                  PropertyType.CURRENCY,
                ]),
                isBindProperty: false,
                isTriggerProperty: false,
                propertyCategory: PropsPanelCategory.Appearance,
              },
              minimumFractionDigitsProperty({
                propertyName: "typeSpecificProps.minimumFractionDigits",
                hidden: showForTypes([
                  PropertyType.NUMBER,
                  PropertyType.CURRENCY,
                  PropertyType.PERCENTAGE,
                ]),
                isBindProperty: false,
              }),
              maximumFractionDigitsProperty({
                propertyName: "typeSpecificProps.maximumFractionDigits",
                hidden: showForTypes([
                  PropertyType.NUMBER,
                  PropertyType.CURRENCY,
                  PropertyType.PERCENTAGE,
                ]),
                isBindProperty: false,
              }),
              {
                propertyName: "typeSpecificProps.linkLabel",
                label: "Link label",
                controlType: "COMPUTE_KEY_VALUE_PROPERTY",
                editorArguments: ["key", "value"],
                isBindProperty: true,
                isTriggerProperty: false,
                isRemovable: true,
                visibility: "SHOW_NAME",
                defaultValue: "{{value}}",
                hidden: showForTypes([PropertyType.LINK]),
              },
              {
                propertyName: "typeSpecificProps.openInNewTab",
                label: "Open in a new tab",
                controlType: "SWITCH",
                defaultValue: true,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: showForTypes([PropertyType.LINK]),
              },

              // image properties
              {
                propertyName: "typeSpecificProps.openImageUrl",
                label: "Open URL on click",
                controlType: "SWITCH",
                helpText:
                  "Opens the image in a new tab when the image is clicked",
                defaultValue: false,
                isBindProperty: true,
                isTriggerProperty: false,
                isJSConvertible: true,
                hidden: showForTypes([PropertyType.IMAGE]),
              },

              // date properties
              {
                propertyName: "typeSpecificProps.dateInputFormat",
                helpText: "Sets the format of the selected date",
                label: "Value format",
                controlType: "DROP_DOWN",
                options: DATE_INPUT_FORMATS,
                isJSConvertible: true,
                hidden: showForTypes([PropertyType.DATE]),
                isBindProperty: true,
                isTriggerProperty: false,
              },
              {
                propertyName: "typeSpecificProps.dateOutputFormat",
                helpText: "Sets the format of the date displayed in the UI",
                label: "Display format",
                controlType: "DROP_DOWN",
                isJSConvertible: true,
                options: DATE_OUTPUT_FORMATS,
                defaultValue: DEFAULT_DATE_OUTPUT_FORMAT,
                hidden: showForTypes([PropertyType.DATE]),
                isBindProperty: true,
                isTriggerProperty: false,
              },
              {
                helpText:
                  "Allows you to control the timezone of the original dates and displayed dates",
                propertyName: "typeSpecificProps.manageTimezone",
                label: "Manage timezone",
                controlType: "SWITCH",
                isJSConvertible: false,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: showForTypes([PropertyType.DATE]),
              },
              {
                helpText: "Timezone of the original date",
                propertyName: "typeSpecificProps.timezone",
                label: "Value timezone",
                controlType: "DROP_DOWN",
                isJSConvertible: false,
                options: TIMEZONE_OPTIONS,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  if (!propertyPath) {
                    return false;
                  }
                  const baseProperty = getParentPath(propertyPath);
                  const manageTimezone = get(
                    props,
                    `${
                      baseProperty
                        ? `${baseProperty}.manageTimezone`
                        : "manageTimezone"
                    }`,
                    "",
                  );
                  return (
                    showForTypes([PropertyType.DATE])(props, propertyPath) ||
                    !manageTimezone
                  );
                },
              },
              {
                helpText: "Timezone of the displayed date",
                propertyName: "typeSpecificProps.displayTimezone",
                label: "Display timezone",
                controlType: "DROP_DOWN",
                isJSConvertible: false,
                options: TIMEZONE_OPTIONS,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  if (!propertyPath) {
                    return false;
                  }
                  const baseProperty = getParentPath(propertyPath);
                  const manageTimezone = get(
                    props,
                    `${
                      baseProperty
                        ? `${baseProperty}.manageTimezone`
                        : "manageTimezone"
                    }`,
                    "",
                  );
                  return (
                    showForTypes([PropertyType.DATE])(props, propertyPath) ||
                    !manageTimezone
                  );
                },
              },
              // boolean properties
              {
                propertyName: "typeSpecificProps.booleanStyleFalse",
                label: "False display",
                controlType: "RADIO_BUTTON",
                controlLayout: ControlLayout.GRID,
                options: [
                  {
                    name: "",
                    value: BooleanStyleFalse.EMPTY,
                  },
                  {
                    name: "Close",
                    isIcon: true,
                    icon: "CLOSE",
                    value: BooleanStyleFalse.CLOSE,
                  },
                  {
                    name: "Minus",
                    isIcon: true,
                    icon: "MINUS",
                    value: BooleanStyleFalse.MINUS,
                  },
                ],
                defaultValue: BooleanStyleFalse.EMPTY,
                isJSConvertible: false,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: showForTypes([PropertyType.BOOLEAN]),
              },
              {
                helpText: "Whether the key-value pair is visible",
                propertyName: "isVisible",
                isJSConvertible: true,
                label: "Visible",
                controlType: "SWITCH",
                isBindProperty: true,
                isTriggerProperty: false,
              },
            ],
          },
          {
            sectionName: "Appearance",
            showHeader: true,
            headerType: "Collapse",
            sectionCategory: PropsPanelCategory.Appearance,
            children: [
              iconProperty({
                propertyName: "keyProps.icon",
                label: "Key icon",
                helpText:
                  "Select an icon to be displayed alongside the property key",
                isBindProperty: false,
              }),
              iconPositionProperty({
                propertyName: "keyProps.iconPosition",
                helpText: "Select the position of the key icon",
                label: "Key icon position",
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const baseProperty = getParentPath(propertyPath);
                  const icon = get(props, `${baseProperty}.icon`, "");
                  return !icon;
                },
              }),
              iconProperty({
                propertyName: "valueProps.icon",
                label: "Value icon",
                helpText:
                  "Select an icon to be displayed alongside the value of the property",
                isBindProperty: false,
                hidden: hideForTypes([
                  PropertyType.BOOLEAN,
                  PropertyType.BUTTON,
                  PropertyType.TAGS,
                  PropertyType.VIDEO,
                  PropertyType.IMAGE,
                ]),
                defaultValue: undefined,
                defaultValueFn: ({
                  props,
                  propertyName,
                }: {
                  props: KeyValueWidgetProps;
                  propertyName: string;
                }) => {
                  const baseValuePropsProperty = getParentPath(propertyName);
                  const baseProperty = baseValuePropsProperty
                    ? getParentPath(baseValuePropsProperty)
                    : "";
                  const type = get(props, `${baseProperty}.type`, "");
                  if (type === PropertyType.EMAIL) {
                    return "{{ icons.mail }}";
                  }
                  return "{{ icons.info }}";
                },
              }),
              iconProperty({
                propertyName: "valueProps.icon",
                label: "Button icon",
                helpText: "Select an icon to be displayed inside the button",
                isBindProperty: false,
                hidden: showForTypes([PropertyType.BUTTON]),
              }),
              iconPositionProperty({
                propertyName: "valueProps.iconPosition",
                helpText: "Select the position of the value icon",
                label: "Value icon position",
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const baseProperty = getParentPath(propertyPath);
                  const icon = get(props, `${baseProperty}.icon`, "");
                  return (
                    !icon ||
                    hideForTypes([PropertyType.BOOLEAN])(props, propertyPath)
                  );
                },
              }),
              {
                propertyName: "typeSpecificProps.imageSize",
                label: "Image sizing",
                controlType: "DROP_DOWN",
                helpText: "Controls the sizing behavior of the image",
                options: [
                  {
                    label: "Fixed",
                    value: ImageSize.Fixed,
                    subText: "Image is cropped to a fixed size",
                  },
                  {
                    label: "Fit",
                    value: ImageSize.Fit,
                    subText:
                      "Image will fill as much space as possible while maintaining the aspect ratio",
                  },
                  {
                    label: "Cover",
                    value: ImageSize.Cover,
                    subText:
                      "Image covers the entire space and is clipped horizontally or vertically as needed",
                  },
                  {
                    label: "Grow vertically",
                    value: ImageSize.Grow,
                    subText:
                      "Image fills the width of the property. If necessary, the row height will grow to maintain the aspect ratio",
                  },
                ],
                isBindProperty: true,
                isJSConvertible: true,
                isTriggerProperty: false,
                defaultValue: ImageSize.Fixed,
                hidden: showForTypes([PropertyType.IMAGE]),
              },
              {
                propertyName: "typeSpecificProps.imageBorderRadius",
                label: "Image border radius",
                controlType: "DROP_DOWN",
                helpText: "Controls the border radius of the image",
                options: [
                  {
                    label: "0px",
                    value: Dimension.px(0),
                  },
                  {
                    label: "4px",
                    value: Dimension.px(4),
                  },
                  {
                    label: "50% (circular)",
                    value: { mode: "%", value: 50 },
                  },
                ],
                isBindProperty: true,
                isJSConvertible: true,
                isTriggerProperty: false,
                defaultValue: { mode: "%", value: 50 },
                optionValueToKey: (value: Dimension) => {
                  return `${value?.value}${value?.mode}`;
                },
                hidden: showForTypes([PropertyType.IMAGE]),
              },
              {
                propertyName: "typeSpecificProps.buttonStyle",
                label: "Button style",
                controlType: "DROP_DOWN",
                helpText: "Changes the style of the button",
                options: BUTTON_STYLE_OPTIONS,
                isJSConvertible: true,
                defaultValue: "PRIMARY_BUTTON",
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: showForTypes([PropertyType.BUTTON]),
              },
              {
                propertyName: "typeSpecificProps.buttonBackgroundColor",
                label: "Background",
                controlType: "COLOR_PICKER",
                helpText: "Changes the color of the button",
                isJSConvertible: true,
                themeValue: THEME_STYLE_DEFAULTS.primaryBackgroundColor,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const buttonType = get(
                    props,
                    `${getParentPath(propertyPath)}.buttonStyle`,
                    "",
                  );
                  return (
                    (buttonType && buttonType !== "PRIMARY_BUTTON") ||
                    showForTypes([PropertyType.BUTTON])(props, propertyPath)
                  );
                },
              },
              {
                propertyName: "typeSpecificProps.buttonLabelColor",
                helpText: "Changes the color of the label",
                label: "Label color",
                controlType: "COLOR_PICKER",
                isJSConvertible: true,
                themeValue: THEME_STYLE_DEFAULTS.primaryTextColor,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const buttonType = get(
                    props,
                    `${getParentPath(propertyPath)}.buttonStyle`,
                    "",
                  );
                  return (
                    (buttonType && buttonType !== "PRIMARY_BUTTON") ||
                    showForTypes([PropertyType.BUTTON])(props, propertyPath)
                  );
                },
              },
              //secondary button styles
              {
                propertyName: "typeSpecificProps.buttonBorderColor",
                label: "Border",
                controlType: "COLOR_PICKER",
                helpText: "Changes the color of the button",
                isJSConvertible: true,
                themeValue: THEME_STYLE_DEFAULTS.secondaryBorderColor,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const buttonType = get(
                    props,
                    `${getParentPath(propertyPath)}.buttonStyle`,
                    "",
                  );
                  return (
                    buttonType !== "SECONDARY_BUTTON" ||
                    showForTypes([PropertyType.BUTTON])(props, propertyPath)
                  );
                },
              },
              {
                propertyName: "typeSpecificProps.buttonLabelColor",
                helpText: "Changes the color of the label",
                label: "Label color",
                controlType: "COLOR_PICKER",
                isJSConvertible: true,
                themeValue: THEME_STYLE_DEFAULTS.secondaryTextColor,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const buttonType = get(
                    props,
                    `${getParentPath(propertyPath)}.buttonStyle`,
                    "",
                  );
                  return (
                    buttonType !== "SECONDARY_BUTTON" ||
                    showForTypes([PropertyType.BUTTON])(props, propertyPath)
                  );
                },
              },
              // tertiary button styles
              {
                propertyName: "typeSpecificProps.buttonLabelColor",
                helpText: "Changes the color of the label",
                label: "Label color",
                controlType: "COLOR_PICKER",
                isJSConvertible: true,
                themeValue: THEME_STYLE_DEFAULTS.tertiaryTextColor,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const buttonType = get(
                    props,
                    `${getParentPath(propertyPath)}.buttonStyle`,
                    "",
                  );
                  return (
                    buttonType !== "TERTIARY_BUTTON" ||
                    showForTypes([PropertyType.BUTTON])(props, propertyPath)
                  );
                },
              },
              {
                propertyName: "typeSpecificProps.textWrap",
                label: "Wrap tags",
                controlType: "SWITCH",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: VALIDATION_TYPES.BOOLEAN,
                helpText: "If enabled, the tags will wrap within the cell",
                hidden: showForTypes([PropertyType.TAGS]),
              },
              {
                propertyName: "typeSpecificProps.tagDisplayConfig",
                label: "Custom tag colors",
                controlType: "CUSTOM_TAG_COLORS",
                headerControlType: "ADD_TAG_COLOR",
                customJSControl: "INPUT_JS_EXPR",
                canExpandEditor: true,
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: showForTypes([PropertyType.TAGS]),
              },
            ],
          },
          {
            sectionName: "Event handlers",
            sectionCategory: PropsPanelCategory.EventHandlers,
            hidden: showForTypes([PropertyType.BUTTON]),
            showHeader: true,
            children: [
              getPopoverConfig(
                "typeSpecificProps.onClick",
                "Triggers an action when the button is clicked",
                {},
                undefined,
                undefined,
                "onClick",
              ),
            ],
          },
        ],
      },
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    labelPositionProperty({
      parentDottedPath: "keyProps",
      defaultValue: WidgetLabelPosition.LEFT,
      label: "Key position",
      hidden: () => false,
      updateHook: ({
        propertyValue,
      }: {
        propertyValue: WidgetLabelPosition;
      }) => {
        if (propertyValue === WidgetLabelPosition.TOP) {
          return [
            {
              propertyPath: "valueProps.position",
              propertyValue: WidgetLabelPosition.LEFT,
            },
          ];
        }
        return [];
      },
    }),
    ...typographyProperties({
      textStyleParentDottedPath: "keyProps",
      propertyNameForHumans: "Key",
      defaultVariant: DEFAULT_KEY_VALUE_WIDGET_KEY_STYLE_VARIANT,
    }),
    ...typographyProperties({
      textStyleParentDottedPath: "valueProps",
      propertyNameForHumans: "Value",
      defaultVariant: DEFAULT_KEY_VALUE_WIDGET_VALUE_STYLE_VARIANT,
    }),
    {
      propertyName: "keyProps.textWrap",
      label: "Wrap key",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      validation: VALIDATION_TYPES.BOOLEAN,
      helpText: "If enabled, the key text will wrap",
    },
    {
      propertyName: "valueProps.textWrap",
      label: "Wrap value",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      validation: VALIDATION_TYPES.BOOLEAN,
      helpText: "If enabled, the value text will wrap",
    },
    ...styleProperties({
      propertyNamespaceDottedPath: "styleProps",
      defaultBorderProperty: DEFAULT_BORDER_OBJECT,
      borderThemeValue: DEFAULT_BORDER_OBJECT,
      backgroundColorDefaultValue: "{{ theme.colors.neutral }}",
      backgroundColorThemeValue: "{{ theme.colors.neutral }}",
      borderRadiusThemeValue: ({ theme }: { theme: any }) => {
        return {
          treatAsNull: false,
          value: createPerCornerBorderRadius(theme.borderRadius),
        };
      },
    }),
    {
      propertyName: "styleProps.divider",
      label: "Divider",
      helpText: "Display dividers between key value pairs",
      controlType: "COLORED_DIMENSION_CONTROL",
      isJSConvertible: false,
      isBindProperty: true,
      isTriggerProperty: false,
      isRemovable: true,
      visibility: "SHOW_NAME",
      defaultValue: {
        size: Dimension.px(1),
        color: "{{ theme.colors.neutral100 }}",
      },
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    paddingProperty({ propertyName: "styleProps.padding" }),
    spacingProperty({
      propertyName: "styleProps.spacing",
      helpText: "Spacing between each key-value pair",
      defaultValue: Dimension.px(24),
      themeValue: ({ theme }: { theme: GeneratedTheme }) => {
        return {
          value: theme.keyValue.spacing,
          treatAsNull: true,
        };
      },
    }),
    sizeSection({ heightSupportsFitContent: true }),
    labelWidthProperty({
      propertyName: "keyProps.width",
      options: [
        {
          label: "Percentage",
          value: "%",
          labelWhenSelected: "%",
          subText: "Key takes up a % of entry's width",
        },
        {
          label: "Pixels",
          labelWhenSelected: "px",
          value: "px",
          subText: "Width in pixels that remains constant",
        },
      ],
      label: "Key width",
      hidden: (props: KeyValueWidgetProps) =>
        props.keyProps?.position === WidgetLabelPosition.TOP,
      updateHook: ({
        props,
        propertyValue,
        propertyPath,
        additionalDataForPropFunc,
      }: {
        props: KeyValueWidgetProps;
        propertyValue: number | "%" | "px";
        propertyPath: string;
        additionalDataForPropFunc?: {
          widgets?: Record<string, WidgetLayoutProps | WidgetPropsRuntime>;
        };
      }) => {
        if (
          !additionalDataForPropFunc?.widgets?.[props.widgetId] ||
          (propertyValue !== "px" && propertyValue !== "%")
        ) {
          return [];
        }

        const parentPath = getParentPath(propertyPath) || "";
        const componentWidth = getComponentDimensions(
          additionalDataForPropFunc.widgets?.[props.widgetId],
        ).componentWidth;
        if (!componentWidth) return [];

        let newPropertyValue;
        if (propertyValue === "px") {
          newPropertyValue = Math.floor(
            (componentWidth * (props.keyProps?.width?.value ?? 0)) / 100,
          );
        } else if (propertyValue === "%") {
          newPropertyValue = Math.ceil(
            ((props.keyProps?.width?.value ?? 0) / componentWidth) * 100,
          );
        }

        return [
          {
            propertyPath: `${parentPath}.value`,
            propertyValue: newPropertyValue,
          },
        ];
      },
    }),
    labelPositionProperty({
      orientation: "horizontal",
      parentDottedPath: "valueProps",
      label: "Value alignment",
      overridePropertyName: "position",
      defaultValue: WidgetLabelPosition.LEFT,
      helpText: "Position the value to the right or left",
      hidden: (props: KeyValueWidgetProps) =>
        props.keyProps?.position === WidgetLabelPosition.TOP,
    }),
    ...visibleProperties({ useJsExpr: false }),
  ]),
];

export default config;
