import { TimeUnit } from "./types";
import type { ChartWidgetProps } from "./ChartWidget";
import type { SortableFieldDef } from "vega-lite/build/src/channeldef";

export function isDefinition(
  props: ChartWidgetProps,
  chartDefinition: "plotly" | "ui",
) {
  return (
    (props.chartDefinition === undefined && chartDefinition === "ui") ||
    props.chartDefinition === chartDefinition
  );
}

export function isXYChart(props: ChartWidgetProps) {
  return props && props.chartType !== "PIE_CHART";
}

export function isNonXYChart(props: ChartWidgetProps) {
  return !isXYChart(props);
}

export function isTemporalXYChart(props: ChartWidgetProps) {
  return (
    props &&
    props.chartType !== "PIE_CHART" &&
    (props.x.dataType === "temporal" ||
      (props.x.dataType === "auto" &&
        props.columnInfo?.find(
          (col) => col.name === props.x.field && col.type === "date",
        )))
  );
}

export function getPrimaryDimensionMeta(
  props: ChartWidgetProps,
): Partial<SortableFieldDef<string>> {
  const dataType = props.x.dataType;
  const columnInfo = props.columnInfo?.find(
    (col) => col.name === props.x.field,
  );

  if (isTemporalXYChart(props)) {
    if (
      props.x.timeUnit === "day" ||
      props.x.timeUnit === "hours" ||
      props.x.timeUnit === "month"
    ) {
      // This is a recommended workaround for non-continuous time units
      // We aren't using "nominal" because we actually want Vega-Lite to apply sorting
      // https://vega.github.io/vega-lite/docs/timeunit.html#time-unit-with-ordinal-fields
      return { type: "ordinal", timeUnit: props.x.timeUnit };
    }
    return { type: "temporal", timeUnit: props.x.timeUnit };
  }

  if (dataType === "auto") {
    if (columnInfo?.type === "number" || columnInfo?.canBeNumeric) {
      return { type: "quantitative" };
    }
    return { type: "nominal" };
  }

  if (dataType === "quantitative") {
    return { type: "quantitative" };
  }

  return {};
}

export function getTimeScale(timeUnit: TimeUnit | undefined) {
  if (!timeUnit) {
    return {};
  }
  switch (timeUnit) {
    case "hours":
      return { scale: { domain: Array.from({ length: 24 }, (_, i) => i) } };
    case "day":
      return { scale: { domain: Array.from({ length: 7 }, (_, i) => i) } };
    case "month":
      return { scale: { domain: Array.from({ length: 12 }, (_, i) => i + 1) } };
  }
  return {};
}
