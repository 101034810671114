import { Tooltip, TooltipProps } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import createLoggedObserver from "utils/createLoggedObserver";

const TextContainer = styled.div<{ $maxLines: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 100%;

  ${({ $maxLines }) =>
    $maxLines > 1
      ? `
        display: -webkit-box;
        -webkit-line-clamp: ${$maxLines};
        -webkit-box-orient: vertical;
        white-space: normal;
      `
      : `
        display: block;
        white-space: nowrap;
      `}
`;

const TruncatedTextTooltip = ({
  text,
  className,
  placement,
  showDelay,
  onClick,
  dataTest,
  maxLines = 1,
  textStyles,
}: {
  text: string;
  className?: string;
  placement?: TooltipProps["placement"];
  showDelay?: number;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  dataTest?: string;
  maxLines?: number;
  textStyles?: React.CSSProperties;
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const stopPropagation = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  useEffect(() => {
    const checkOverflow = debounce(() => {
      const element = textRef.current;
      if (element) {
        setIsOverflowing(
          element.scrollHeight > element.clientHeight ||
            element.scrollWidth > element.clientWidth,
        );
      }
    }, 100);

    checkOverflow();

    const resizeObserver = createLoggedObserver(
      "TruncatedTextTooltip",
      checkOverflow,
    );
    if (textRef.current) {
      resizeObserver.observe(textRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      checkOverflow.cancel();
    };
  }, [text]);

  return (
    <div style={{ maxWidth: "100%" }}>
      <Tooltip
        title={isOverflowing ? <div onClick={stopPropagation}>{text}</div> : ""}
        placement={placement}
        mouseEnterDelay={showDelay}
      >
        <TextContainer
          ref={textRef}
          className={className}
          onClick={onClick}
          data-test={dataTest}
          $maxLines={maxLines}
          style={textStyles}
        >
          {text}
        </TextContainer>
      </Tooltip>
    </div>
  );
};

export default TruncatedTextTooltip;
