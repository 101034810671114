import { Dimension } from "@superblocksteam/shared";
import { isEqual } from "lodash";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { GeneratedTheme } from "legacy/themes";
import {
  DEFAULT_DATE_OUTPUT_FORMAT,
  formatNumber,
  formatPercentage,
} from "legacy/utils/FormatUtils";
import { formatCurrency } from "legacy/utils/FormatUtils";
import { formatDate } from "legacy/utils/FormatUtils";
import { TagsColorAssignment } from "../TableWidget/TableComponent/Constants";
import { sanitizeCellValue } from "../TableWidget/TableComponent/TableUtilities";
import { getLineHeightPx } from "../TextWidget/TextComponent";
import { DEFAULT_BORDER_OBJECT } from "./constants";
import { KeyValueProperty, PropertyType } from "./types";

const INVALID_NUMBER_TEXT = "Invalid number";

export const getDerivedPropertiesKeys = (
  properties: Record<string, KeyValueProperty>,
) => {
  return Object.keys(properties).filter((key) => properties[key].isDerived);
};

export const getStringValue = (text: unknown): undefined | string => {
  if (text === undefined || text === null) {
    return undefined;
  }

  if (typeof text === "string" || typeof text === "number") {
    return text.toString();
  }

  return JSON.stringify(text);
};

// This logic should match the one in KeyValueWidget's data derived property
export const toNumericValue = (text: string): string | number => {
  const trimmed = text.trim();
  if (!trimmed) return "";
  const num = Number(trimmed);
  return !isNaN(num) && isFinite(num) ? num : INVALID_NUMBER_TEXT;
};

export const getValueByType = (
  property: KeyValueProperty,
  data: Record<string, unknown>,
): string | boolean => {
  const text = `${property.displayedValue ?? (property.isDerived ? `${property.computedValue}` : `${data[property.id]}`)}`;
  switch (property.type) {
    case PropertyType.CURRENCY: {
      const textToNumber = toNumericValue(text);
      if (typeof textToNumber === "string") return textToNumber;
      return formatCurrency(
        textToNumber,
        property.typeSpecificProps?.currency,
        property.typeSpecificProps?.notation,
        property.typeSpecificProps?.minimumFractionDigits,
        property.typeSpecificProps?.maximumFractionDigits,
      );
    }
    case PropertyType.NUMBER: {
      const textToNumber = toNumericValue(text);
      if (typeof textToNumber === "string") return textToNumber;
      return formatNumber(
        textToNumber,
        property.typeSpecificProps?.notation,
        property.typeSpecificProps?.minimumFractionDigits,
        property.typeSpecificProps?.maximumFractionDigits,
      );
    }
    case PropertyType.PERCENTAGE: {
      const textToNumber = toNumericValue(text);
      if (typeof textToNumber === "string") return textToNumber;
      return formatPercentage(
        textToNumber,
        property.typeSpecificProps?.minimumFractionDigits,
        property.typeSpecificProps?.maximumFractionDigits,
      );
    }
    case PropertyType.DATE: {
      return formatDate(
        text,
        property.typeSpecificProps?.dateInputFormat,
        property.typeSpecificProps?.dateOutputFormat ??
          DEFAULT_DATE_OUTPUT_FORMAT,
        property.typeSpecificProps?.manageTimezone
          ? property.typeSpecificProps?.timezone
          : undefined,
        property.typeSpecificProps?.manageTimezone
          ? property.typeSpecificProps?.displayTimezone
          : undefined,
      );
    }
    case PropertyType.BOOLEAN: {
      return !!(
        property &&
        (property.isDerived ? property.computedValue : data[property.id])
      );
    }
    default:
      return getStringValue(text) ?? "";
  }
};

export const toReadableKey = (key: string): string => {
  return key
    .replace(/[_-]/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .trim()
    .toLowerCase()
    .replace(/^\w/, (c: string) => c.toUpperCase());
};

export const getProperties = (
  data: Record<string, unknown>,
  currentProperties: Record<string, KeyValueProperty> = {},
  cachedProperties: Record<string, KeyValueProperty> = {},
): Record<string, KeyValueProperty> => {
  const derivedProperties = Object.values(currentProperties).filter(
    (property) => property?.isDerived,
  );

  const baseProperties = derivedProperties.reduce(
    (acc, property) => {
      acc[property.id] = property;
      return acc;
    },
    {} as Record<string, KeyValueProperty>,
  );

  return Object.keys(data).reduce((acc, key) => {
    const cachedDefinition: Partial<KeyValueProperty> =
      currentProperties[key] ?? cachedProperties[key] ?? {};
    acc[key] = {
      ...getDefaultProperty(key),
      ...cachedDefinition,
    } as KeyValueProperty;
    return acc;
  }, baseProperties);
};

export const getDefaultProperty = (key: string): KeyValueProperty => {
  return {
    id: key,
    key,
    label: toReadableKey(key),
    type: "text",
    isVisible: true,
    isDerived: false,
  };
};

export const isDefaultProperty = (property: KeyValueProperty): boolean => {
  return isEqual(property, getDefaultProperty(property.key));
};

export const getTagsColorAssignment = (
  tagValues: string[],
): TagsColorAssignment => {
  return {
    uniqueTagsCount: tagValues.length,
    mapping: tagValues.reduce(
      (acc, tag, index) => {
        acc[sanitizeCellValue(tag)] = index;
        return acc;
      },
      {} as Record<string, number>,
    ),
  };
};

export const estimateInitialKeyValueWidgetHeightGU = ({
  theme,
  rowCount,
}: {
  theme?: GeneratedTheme;
  rowCount: number;
}): number => {
  if (!theme?.typographies) {
    return 14;
  }

  const textHeightPx = getLineHeightPx({}, theme?.typographies);
  const { padding, spacing } = theme.keyValue;

  return Dimension.toGridUnit(
    Dimension.px(
      (padding.top?.value ?? 0) +
        (padding.bottom?.value ?? 0) +
        (DEFAULT_BORDER_OBJECT.top.width?.value ?? 0) * 2 +
        textHeightPx * rowCount +
        (spacing.value ?? 0) * (rowCount - 1),
    ),
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).raw().value;
};
