import { ApplicationScope } from "@superblocksteam/shared";
import React, { useCallback, useState } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import DynamicSVG from "components/ui/DynamicSVG";
import { EventType } from "legacy/constants/ActionConstants";
import { RadioButtonControlIcons } from "legacy/icons/BooleanValueIcons";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { RunWidgetEventHandlers } from "legacy/widgets/BaseWidget";
import { LinkComponent } from "legacy/widgets/LinkWidget/LinkComponent";
import { Button } from "legacy/widgets/Shared/Button";
import {
  BooleanStyleFalse,
  parseImageURLValue,
  ImageSize,
} from "legacy/widgets/Shared/ValueWithTypeUtils";
import PopoverVideo from "legacy/widgets/TableWidget/TableComponent/PopoverVideo";
import { TagsCell } from "legacy/widgets/TableWidget/TableComponent/TableUtilities";
import { LinkToType } from "legacy/widgets/navigationProperties";
import { DEFAULT_ICON_SIZE } from "legacy/widgets/shared";
import { TypographyProps } from "legacy/widgets/typographyHooks";
import {
  getFontSizeInPxFromTextStyle,
  getIconSizeBasedOnFontSize,
} from "legacy/widgets/typographyUtils";
import { createPerSideBorder } from "pages/Editors/AppBuilder/Sidebar/BorderEditor";
import { addNewPromise } from "store/utils/resolveIdSingleton";
import { isValidUrl } from "utils/url";
import {
  KeyValueComponentProps,
  KeyValueProperty,
  PropertyType,
} from "../types";
import { getValueByType, getTagsColorAssignment } from "../utils";
import { getStringValue } from "../utils";
import {
  ImageWrapperClass,
  TagCellWrapperClass,
  useValueClassNames,
  ValueContentWrapperClass,
  ValueWrapperClass,
} from "./valueStyles";

export const KeyValuePropertyValue = (
  props: {
    property: KeyValueProperty;
    data: Record<string, unknown>;
    runEventHandlers: (payload: RunWidgetEventHandlers) => void;
    iconSize?: number;
    valueStyles?: TypographyProps;
    linkStyles?: TypographyProps;
  } & Pick<
    KeyValueComponentProps,
    "valueProps" | "componentWidth" | "componentHeight"
  >,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const { property, data, runEventHandlers, valueStyles, valueProps } = props;

  const valueClassNames = useValueClassNames(valueProps);
  const theme = useSelector(selectGeneratedTheme);

  const handleButtonClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (property.typeSpecificProps?.onClick && !isLoading) {
        setIsLoading(true);
        const callbackId = addNewPromise(() => setIsLoading(false));

        runEventHandlers({
          steps: property.typeSpecificProps.onClick,
          type: EventType.ON_CLICK,
          callbackId: callbackId as string,
          currentScope: ApplicationScope.PAGE,
        });
      }
    },
    [property.typeSpecificProps?.onClick, isLoading, runEventHandlers],
  );

  const content = useMemo(() => {
    const icon = property.valueProps?.icon ? (
      <DynamicSVG
        iconName={property.valueProps.icon}
        size={props.iconSize ?? DEFAULT_ICON_SIZE}
      />
    ) : null;
    const iconPosition = property.valueProps?.iconPosition ?? "LEFT";
    const value = property.isDerived
      ? property.computedValue
      : data[property.id];

    switch (property.type) {
      case PropertyType.BUTTON: {
        const borderColor = property.typeSpecificProps?.buttonBorderColor;
        const backgroundColor =
          property.typeSpecificProps?.buttonBackgroundColor;
        const buttonStyle = property.typeSpecificProps?.buttonStyle;
        const buttonLabel = getStringValue(
          property.typeSpecificProps?.buttonLabel,
        );
        const buttonLabelColor = property.typeSpecificProps?.buttonLabelColor;
        const isDisabled = property.typeSpecificProps?.isDisabled;
        const buttonIconSize = getIconSizeBasedOnFontSize(
          getFontSizeInPxFromTextStyle({
            nestedProps: buttonStyle,
            typographies: theme.typographies,
            textStyleVariant: "buttonLabel",
            defaultTextStyleVariant: "buttonLabel",
          }),
        );
        const buttonIcon = property.valueProps?.icon ? (
          <DynamicSVG
            iconName={property.valueProps.icon}
            size={buttonIconSize ?? DEFAULT_ICON_SIZE}
          />
        ) : null;
        return (
          <Button
            version="v2"
            disabled={isDisabled}
            buttonStyle={buttonStyle ?? "PRIMARY_BUTTON"}
            backgroundColor={backgroundColor}
            border={
              borderColor
                ? createPerSideBorder({
                    color: borderColor ?? "#000000",
                    width: 1,
                  })
                : undefined
            }
            text={buttonLabel ?? "Click me"}
            icon={buttonIcon}
            iconPosition={iconPosition}
            textColor={buttonLabelColor}
            isLoading={isLoading}
            onClick={handleButtonClick}
            width="auto"
            height="100%"
          />
        );
      }
      case PropertyType.LINK: {
        const url = value ? value.toString() : "";
        const label = getStringValue(property.typeSpecificProps?.linkLabel);
        return (
          <LinkComponent
            url={url}
            linkTo={LinkToType.CustomUrl}
            text={label || url}
            isLoading={false}
            linkStyle="LINK"
            textProps={{
              textStyleVariant: props.linkStyles?.textStyleVariant ?? "link",
              style: props.linkStyles?.style,
            }}
            textColor={theme?.typographies.link.textColor.default}
            icon={icon}
            iconPosition={iconPosition}
            openInNewTab={property.typeSpecificProps?.openInNewTab ?? true}
          />
        );
      }

      case PropertyType.BOOLEAN: {
        const ButtonIcon = getValueByType(property, data)
          ? RadioButtonControlIcons["CHECK"]
          : RadioButtonControlIcons[
              property.typeSpecificProps?.booleanStyleFalse ||
                BooleanStyleFalse.EMPTY
            ];
        return <ButtonIcon />;
      }
      case PropertyType.IMAGE: {
        const imageUrls = parseImageURLValue(value);
        if (!imageUrls || imageUrls.length === 0) {
          return <div>Invalid Image</div>;
        }

        return imageUrls.map((item: string, index: number) => {
          if (isValidUrl(item)) {
            const imageStyle = {
              borderRadius:
                typeof property.typeSpecificProps?.imageBorderRadius ===
                "string"
                  ? property.typeSpecificProps?.imageBorderRadius
                  : property.typeSpecificProps?.imageBorderRadius
                    ? `${property.typeSpecificProps.imageBorderRadius.value}${property.typeSpecificProps.imageBorderRadius.mode}`
                    : "50%",
            };
            if (property.typeSpecificProps?.openImageUrl) {
              return (
                <a
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item}
                  className={ImageWrapperClass}
                  key={index}
                  data-image-size={
                    property.typeSpecificProps?.imageSize ?? ImageSize.Fixed
                  }
                >
                  <img src={item} alt="" style={imageStyle} />
                </a>
              );
            }
            return (
              <div
                className={ImageWrapperClass}
                key={index}
                data-image-size={
                  property.typeSpecificProps?.imageSize ?? ImageSize.Fixed
                }
              >
                <img src={item} alt="" style={imageStyle} />
              </div>
            );
          } else {
            return (
              <div className={ValueContentWrapperClass} key={index}>
                Invalid Image
              </div>
            );
          }
        });
      }
      case PropertyType.VIDEO: {
        return <PopoverVideo url={value as string} />;
      }
      case PropertyType.TAGS: {
        let displayValue = value;
        if (displayValue && typeof displayValue === "string") {
          // handle case where displayedValue is a stringified array
          displayValue = displayValue.split(",");
        } else if (
          typeof value === "string" ||
          typeof value === "number" ||
          typeof value === "boolean"
        ) {
          displayValue = [value];
        }

        if (!Array.isArray(displayValue)) {
          return null;
        }

        const tagsColorAssignment = getTagsColorAssignment(displayValue);

        return (
          <div className={TagCellWrapperClass}>
            <TagsCell
              // Update when component width or height changes, otherwise the tags will not re-render
              key={`${props.componentWidth}-${props.componentHeight}`}
              cellProperties={{ textWrap: !!valueProps?.textWrap }}
              isHidden={false}
              tagValues={displayValue}
              tagsColorAssignment={tagsColorAssignment}
              tagsCustomColorAssignment={
                property.typeSpecificProps?.tagDisplayConfig
              }
              compactMode="SHORT"
            />
          </div>
        );
      }
      case PropertyType.EMAIL: {
        const value = getValueByType(property, data);
        return (
          <a className={ValueContentWrapperClass} href={`mailto:${value}`}>
            {iconPosition === "LEFT" && icon}
            <p>{value}</p>
            {iconPosition === "RIGHT" && icon}
          </a>
        );
      }
      default:
        return (
          <div className={ValueContentWrapperClass}>
            {iconPosition === "LEFT" && icon}
            <p>{getValueByType(property, data)}</p>
            {iconPosition === "RIGHT" && icon}
          </div>
        );
    }
  }, [
    property,
    data,
    handleButtonClick,
    isLoading,
    props.iconSize,
    valueProps?.textWrap,
    props.componentWidth,
    props.componentHeight,
    theme,
    props.linkStyles,
  ]);

  return (
    <div
      className={`${ValueWrapperClass} ${valueClassNames}`}
      style={valueStyles?.style}
    >
      {content}
    </div>
  );
};
