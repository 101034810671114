import { useMemo } from "react";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { KeyValueComponentProps } from "../types";

export const PropertyKeyWrapperClass = styleAsClass`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${colors.GREY_500};
  flex-grow: 1;
  max-width: 100%;

  p {
    margin-bottom: 0;
  }

  &:not(${CLASS_NAMES.KEY_VALUE_ENTRY_ELLIPSIS}) > p {
    word-break: break-word;
  }

  &.${CLASS_NAMES.KEY_VALUE_ENTRY_ELLIPSIS} > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const useKeyClassNames = (
  keyProps?: KeyValueComponentProps["keyProps"],
) => {
  return useMemo(() => {
    let classNames = PropertyKeyWrapperClass;

    if (!keyProps?.textWrap) {
      classNames += ` ${CLASS_NAMES.KEY_VALUE_ENTRY_ELLIPSIS}`;
    }

    return classNames;
  }, [keyProps?.textWrap]);
};
