import { ApplicationScope, Dimension } from "@superblocksteam/shared";
import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { useCallbackAsRef } from "hooks/ui";
import { updateWidgetProperties } from "legacy/actions/controlActions";
import {
  WidgetHeightConstraintType,
  WidgetTypes,
  WidgetWidthConstraintType,
} from "legacy/constants/WidgetConstants";
import { AiEditsAccessor } from "legacy/pages/Editor/PropertyPane/accessors/AiEditsAccessor";
import { getResponsiveCanvasWidth } from "legacy/selectors/applicationSelectors";
import { getFlattenedCanvasWidget } from "legacy/selectors/editorSelectors";
import { getCanvasWidgets } from "legacy/selectors/entitiesSelector";
import { getSelectedWidgetsIds } from "legacy/selectors/sagaSelectors";
import WidgetHeightConstraintResizer from "legacy/widgets/SectionWidget/WidgetHeightConstraintResizer/WidgetHeightConstraintResizer";
import { useWidgetFocusHandlers } from "legacy/widgets/base/useWidgetFocusHandlers";
import {
  heightModeSupportsMinMax,
  widthModeSupportsMinMax,
} from "legacy/widgets/layoutProperties";
import { FlattenedWidgetLayoutProps } from "legacy/widgets/shared";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { selectAiChangedProperties } from "store/slices/ai/selectors";
import { selectFlags } from "store/slices/featureFlags";
import { useWidgetConstraintResizers } from "../WidgetHeightConstraintResizer/constraintResizerHooks";
import WidgetWidthConstraintResizer from "../widgetWidthContraintResizer/WidgetWidthConstraintResizer";
import { useWidgetWidthConstraintResizers } from "../widgetWidthContraintResizer/widthConstraintResizerHooks";
import type { AppState } from "store/types";

type Props = {
  widget: FlattenedWidgetLayoutProps;
  sectionWidgetId: string;
  sectionContentRef: React.RefObject<HTMLDivElement>;
};

const computePositions = (
  sectionElem: HTMLElement,
  selectedWidgetId: string,
) => {
  const sectionRect = sectionElem.getBoundingClientRect();

  const selectedDomId = `widget-${selectedWidgetId}`;
  const selectedRect = document
    .getElementById(selectedDomId)
    ?.getBoundingClientRect();

  return { sectionRect, selectedRect };
};

const usePositions = (
  sectionRef: React.RefObject<HTMLElement> | null,
  selectedWidgetId: string | undefined,
) => {
  const sectionElem = sectionRef?.current;

  const [positions, setPositions] = useState<{
    sectionRect: undefined | DOMRect;
    selectedRect: undefined | DOMRect;
  }>({
    sectionRect: undefined,
    selectedRect: undefined,
  });

  const animRef = useRef<number | undefined>(undefined);
  const _mainUpdate = useCallback(() => {
    if (sectionElem && selectedWidgetId) {
      animRef.current = requestAnimationFrame(() => {
        animRef.current != null && cancelAnimationFrame(animRef.current);
        const { sectionRect, selectedRect } = computePositions(
          sectionElem,
          selectedWidgetId,
        );
        setPositions({ sectionRect, selectedRect });
      });
    }
  }, [selectedWidgetId, sectionElem]);

  const mainUpdateRef = useCallbackAsRef(() => _mainUpdate());

  const canvasWidgets = useAppSelector(getCanvasWidgets);
  const width = useAppSelector(getResponsiveCanvasWidth);

  useEffect(() => {
    // adding the time out because widget ele's position is still changing while width state is already updated
    // which might cause the element position to be wrong when calling computePositions
    const timeouId = setTimeout(() => {
      mainUpdateRef();
    }, 200);
    return () => {
      clearTimeout(timeouId);
    };
    // re-check size when these deps change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_mainUpdate, canvasWidgets, width]);

  useEffect(() => {
    // first initial render without timeout
    mainUpdateRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_mainUpdate, canvasWidgets]);

  useEffect(() => {
    if (sectionElem) {
      const listener = () => {
        mainUpdateRef();
      };
      sectionElem.addEventListener("scroll", listener, true);
      return () => {
        sectionElem.removeEventListener("scroll", listener);
      };
    }
  }, [sectionElem, mainUpdateRef]);

  return {
    positions,
    updatePositions: mainUpdateRef,
  };
};

const SelectedWidgetConstraintResizers = (props: Props) => {
  const { widget, sectionContentRef } = props;

  const supportsMinMaxHeight = heightModeSupportsMinMax(widget.height.mode);
  const showMinHeightControl = supportsMinMaxHeight && widget.minHeight;
  const showMaxHeightControl = supportsMinMaxHeight && widget.maxHeight;

  const supoprtedMinMaxWidth = widthModeSupportsMinMax(widget.width.mode);
  const showMinWidthControl = supoprtedMinMaxWidth && widget.minWidth;
  const showMaxWidthControl = supoprtedMinMaxWidth && widget.maxWidth;

  const {
    isResizingConstraint,
    setIsResizingConstraint,
    resizerMaxHeight,
    resizerMinHeight,
    useStateHeight,
    setResizingConstraintHeightPx,
  } = useWidgetConstraintResizers(props.widget);

  const {
    isResizingConstraintWidth,
    setIsResizingConstraintWidth,
    resizerMaxWidth,
    resizerMinWidth,
    useStateWidth,
    setResizingConstraintWidthPx,
  } = useWidgetWidthConstraintResizers(props.widget);

  const dispatch = useAppDispatch();
  const flags = useAppSelector(selectFlags);
  const aiChangedProperties = useAppSelector((state) =>
    selectAiChangedProperties(state, widget.widgetId),
  );

  const updateProperties = useCallback(
    (
      constraintType: WidgetHeightConstraintType | WidgetWidthConstraintType,
      value: Dimension<"gridUnit"> | Dimension<"px">,
    ) => {
      const constraintIsMixMax = [
        "minHeight",
        "maxHeight",
        "minWidth",
        "maxWidth",
      ].includes(constraintType);

      if (constraintIsMixMax && aiChangedProperties.includes(constraintType)) {
        AiEditsAccessor.updateItemProperties(
          dispatch,
          widget as any,
          {
            [constraintType]: value,
          },
          ApplicationScope.PAGE,
          flags,
        );
      } else {
        dispatch(
          updateWidgetProperties(widget.widgetId, {
            [constraintType]: value,
          }),
        );
      }
    },
    [dispatch, widget, aiChangedProperties, flags],
  );

  const handleResizeEnded = useCallback(
    (
      constraintType: WidgetHeightConstraintType,
      height: Dimension<"gridUnit">,
    ) => {
      updateProperties(constraintType, height);
    },
    [updateProperties],
  );

  const handleResizeEndedWidth = useCallback(
    (constraintType: WidgetWidthConstraintType, width: Dimension<"px">) => {
      updateProperties(constraintType, width);
    },
    [updateProperties],
  );

  const { positions, updatePositions } = usePositions(
    sectionContentRef,
    widget.widgetId,
  );
  const { sectionRect, selectedRect } = positions;

  const widgetRelativePosition = useMemo(() => {
    if (!sectionRect || !selectedRect) return undefined;
    return {
      top: selectedRect.top - sectionRect.top,
      left: selectedRect.left - sectionRect.left,
      width: selectedRect.width,
      height: selectedRect.height,
    };
  }, [sectionRect, selectedRect]);

  const isDraggingWidget = useAppSelector(
    (state: AppState) => state.legacy.ui.widgetDragResize.isDragging,
  );

  const minHandlers = useWidgetFocusHandlers(props.widget);
  const maxHandlers = useWidgetFocusHandlers(props.widget);

  useEffect(() => {
    updatePositions();
  }, [
    widget.maxWidth,
    widget.minWidth,
    widget.maxHeight,
    widget.minHeight,
    updatePositions,
  ]);

  if ((isDraggingWidget && !isResizingConstraint) || !widgetRelativePosition) {
    return null;
  }

  return (
    <>
      {showMinHeightControl && (
        <div
          onMouseOver={minHandlers.handleMouseOver}
          onMouseOut={minHandlers.handleMouseOut}
          ref={minHandlers.wrapperRef}
        >
          <WidgetHeightConstraintResizer
            widgetRelativePosition={widgetRelativePosition}
            height={resizerMinHeight}
            constraintLabel={"Min"}
            constraintType={"minHeight"}
            isResizing={isResizingConstraint && useStateHeight === "minHeight"}
            setIsResizingConstraint={setIsResizingConstraint}
            setResizingHeight={setResizingConstraintHeightPx}
            onResizeEnded={handleResizeEnded}
          />
        </div>
      )}
      {showMaxHeightControl && (
        <div
          onMouseOver={maxHandlers.handleMouseOver}
          onMouseOut={maxHandlers.handleMouseOut}
          ref={maxHandlers.wrapperRef}
        >
          <WidgetHeightConstraintResizer
            widgetRelativePosition={widgetRelativePosition}
            constraintType={"maxHeight"}
            height={resizerMaxHeight}
            constraintLabel={"Max"}
            isResizing={isResizingConstraint && useStateHeight === "maxHeight"}
            setIsResizingConstraint={setIsResizingConstraint}
            setResizingHeight={setResizingConstraintHeightPx}
            onResizeEnded={handleResizeEnded}
          />
        </div>
      )}
      {showMinWidthControl && (
        <div
          onMouseOver={minHandlers.handleMouseOver}
          onMouseOut={minHandlers.handleMouseOut}
          ref={minHandlers.wrapperRef}
        >
          <WidgetWidthConstraintResizer
            widgetRelativePosition={widgetRelativePosition}
            width={resizerMinWidth}
            constraintLabel={"Min"}
            constraintType={"minWidth"}
            isResizing={
              isResizingConstraintWidth && useStateWidth === "minWidth"
            }
            setIsResizingConstraintWidth={setIsResizingConstraintWidth}
            setResizingWidth={setResizingConstraintWidthPx}
            onResizeEnded={handleResizeEndedWidth}
          />
        </div>
      )}
      {showMaxWidthControl && (
        <div
          onMouseOver={maxHandlers.handleMouseOver}
          onMouseOut={maxHandlers.handleMouseOut}
          ref={maxHandlers.wrapperRef}
        >
          <WidgetWidthConstraintResizer
            widgetRelativePosition={widgetRelativePosition}
            constraintType={"maxWidth"}
            width={resizerMaxWidth}
            constraintLabel={"Max"}
            isResizing={
              isResizingConstraintWidth && useStateWidth === "maxWidth"
            }
            setIsResizingConstraintWidth={setIsResizingConstraintWidth}
            setResizingWidth={setResizingConstraintWidthPx}
            onResizeEnded={handleResizeEndedWidth}
          />
        </div>
      )}
    </>
  );
};

type ExternalProps = {
  sectionWidgetId: string;
  sectionContentRef: React.RefObject<HTMLDivElement>;
};

const ConstraintResizerWrapper = (props: ExternalProps) => {
  const { sectionWidgetId, sectionContentRef } = props;
  const selectedWidgetIds = useAppSelector((state: AppState) =>
    getSelectedWidgetsIds(state),
  );
  const canvasWidgets = useAppSelector(getCanvasWidgets);

  const selectedChildWidgetId = useMemo(() => {
    // traverse ancestry to see if sectionWidgetId is first section parent of this widget
    if (
      selectedWidgetIds.length !== 1 ||
      canvasWidgets[selectedWidgetIds[0]]?.type === WidgetTypes.CANVAS_WIDGET // don't care about column canvases
    ) {
      return undefined;
    }
    let currentWidgetId = selectedWidgetIds[0];
    while (currentWidgetId) {
      const currentWidget = canvasWidgets[currentWidgetId];
      if (currentWidget?.type === WidgetTypes.SECTION_WIDGET) {
        return undefined;
      }
      if (currentWidget && currentWidget.parentId === sectionWidgetId) {
        return selectedWidgetIds[0];
      }
      currentWidgetId = currentWidget?.parentId;
    }
    return undefined;
  }, [selectedWidgetIds, sectionWidgetId, canvasWidgets]);

  const widget = useAppSelector((state: AppState) =>
    selectedChildWidgetId
      ? getFlattenedCanvasWidget(state, selectedChildWidgetId)
      : undefined,
  );

  if (widget) {
    return (
      <SelectedWidgetConstraintResizers
        widget={widget}
        sectionWidgetId={sectionWidgetId}
        sectionContentRef={sectionContentRef}
      />
    );
  }
  return null;
};

export default ConstraintResizerWrapper;
