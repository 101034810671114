import { useMemo } from "react";
import { WidgetLabelPosition } from "legacy/constants/WidgetConstants";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { styleAsClass } from "styles/styleAsClass";
import { KeyValueComponentProps } from "../types";

export const ValueContentWrapperClass = styleAsClass`
  display: flex;
  gap: 5px;
  max-width: 100%;
  align-items: center;
`;

export const ValueWrapperClass = styleAsClass`
  display: flex;
  overflow: hidden;

  [data-dd-action-name="link"] {
    max-width: 100%;

    a {
      text-align: start;
      max-width: 100%;

      span {
        // The following fixes the italic text getting cut off at the right
        padding-right: 2px;
      }
    }
  }
  
  p {
    margin-bottom: 0;
    // The following fixes the italic text getting cut off at the right
    padding-right: 2px;
  }

  &.${CLASS_NAMES.KEY_VALUE_ENTRY_VALUE_RIGHT_ALIGN} {
    justify-content: end;
    text-align: end;
    flex-grow: 1;

    [data-dd-action-name="link"] a {
      text-align: end;
    }
  }

  &.${CLASS_NAMES.KEY_VALUE_ENTRY_ELLIPSIS} {
    .${ValueContentWrapperClass} {
      > p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &:not(.${CLASS_NAMES.KEY_VALUE_ENTRY_ELLIPSIS}) {
    word-break: break-word;

    .button-text, .${ValueContentWrapperClass} p {
      white-space: unset;
      word-break: break-word;
    }
  }
`;

export const ImageWrapperClass = styleAsClass`
  img {
    vertical-align: top;
  }

  &[data-image-size="FIXED"] {
    width: 32px;
    height: 32px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &[data-image-size="FIT"] {
    height: 32px;
    width: 100%;
    overflow: hidden;
    img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
    }
  }

  &[data-image-size="COVER"] {
    height: 32px;
    width: 100%;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &[data-image-size="GROW"] {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
`;

export const TagCellWrapperClass = styleAsClass`
  max-width: 100%;
  
  > div {
    padding: 0;
  }
`;

export const useValueClassNames = (
  valueProps: KeyValueComponentProps["valueProps"],
) => {
  return useMemo(() => {
    let classNames = ValueWrapperClass;
    const isValueRightAligned =
      valueProps?.position === WidgetLabelPosition.RIGHT;

    if (isValueRightAligned) {
      classNames += ` ${CLASS_NAMES.KEY_VALUE_ENTRY_VALUE_RIGHT_ALIGN}`;
    }

    if (!valueProps?.textWrap) {
      classNames += ` ${CLASS_NAMES.KEY_VALUE_ENTRY_ELLIPSIS}`;
    }

    return classNames;
  }, [valueProps?.position, valueProps?.textWrap]);
};
