import { get, unset } from "lodash";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";

import { getIsColumn, getIsTab } from "legacy/selectors/entitiesSelector";
import { getWidgetPropertiesById } from "legacy/selectors/propertyPaneSelectors";
import { selectWidgetDisplayName } from "legacy/selectors/sagaSelectors";
import { mergeUpdatesWithBindingsOrTriggers } from "legacy/utils/DynamicBindingUtils";
import { isSubProperty } from "legacy/utils/ai";
import { useAppSelector } from "store/helpers";
import { selectAiState } from "store/slices/ai/selectors";
import { updateAiChanges, removeChildInAiChanges } from "store/slices/ai/slice";
import { AppState } from "store/types";
import { fastClone } from "utils/clone";
import { ItemKinds, ItemTypeNonWidget } from "../ItemKindConstants";
import { getItemPropertyPaneConfig } from "../ItemPropertyPaneConfig";
import type { ItemKindAccessor } from "../ItemKinds";

export const AiEditsAccessor: ItemKindAccessor<ItemKinds.AI_EDITS> = {
  useItemName: (itemProperties: any) => {
    const displayName = useAppSelector(
      useCallback(
        (state: AppState) =>
          selectWidgetDisplayName(state, itemProperties.widgetId),
        [itemProperties.widgetId],
      ),
    );
    const isTab = useAppSelector(
      useCallback(
        (state: AppState) => getIsTab(state, itemProperties.widgetId),
        [itemProperties.widgetId],
      ),
    );
    const isColumn = useAppSelector(
      useCallback(
        (state: AppState) => getIsColumn(state, itemProperties.widgetId),
        [itemProperties.widgetId],
      ),
    );
    return useMemo(
      () => ({
        name: itemProperties.widgetName,
        displayName,
        editable: !isColumn,
        requiresValidation: !isTab,
      }),
      [displayName, isColumn, isTab, itemProperties.widgetName],
    );
  },
  itemType: () => ItemTypeNonWidget.AI_EDITS,
  useItemProperties: (itemId: string) => {
    const baseWidget = useAppSelector(
      useCallback(
        (state: AppState) =>
          getWidgetPropertiesById(state, itemId, "EXCLUDE_AI_STATE"),
        [itemId],
      ),
    );
    const { dataTreeChanges } = useSelector(selectAiState);

    return Object.assign({}, baseWidget, dataTreeChanges?.[itemId]) as any;
  },
  useAreItemPropertiesLoading: (itemId: string, propertyNames: string[]) => {
    const {
      changedKeys: changedKeysByWidgetId,
      propertiesToChange: propertiesToChangeByWidgetId,
      dataTreeChanges: dataTreeChangesByWidgetId,
    } = useAppSelector(selectAiState);

    const dataTreeChanges = dataTreeChangesByWidgetId?.[itemId] || {};

    const changedKeys = changedKeysByWidgetId?.[itemId];
    const propertiesToChange = propertiesToChangeByWidgetId?.[itemId];

    return propertyNames.map((propertyName) => {
      const isInChangeSet =
        changedKeys != null &&
        (changedKeys?.includes(propertyName) ||
          get(dataTreeChanges, propertyName) != null ||
          (changedKeys ?? [])?.some((key) => isSubProperty(propertyName, key)));
      if (isInChangeSet) return false;

      const isInExpectedSet =
        propertiesToChange != null &&
        (propertiesToChange?.includes(propertyName) ||
          (propertiesToChange ?? [])?.some((key) =>
            isSubProperty(propertyName, key),
          ));
      return isInExpectedSet;
    });
  },
  updateItemProperties: (
    dispatch: Dispatch<any>,
    properties: any,
    updates: Record<string, unknown>,
  ) => {
    const updatesWithBindings = mergeUpdatesWithBindingsOrTriggers(
      properties,
      getItemPropertyPaneConfig(properties.type),
      updates,
      true,
    );

    dispatch(
      updateAiChanges({
        updates: updatesWithBindings,
        properties,
      }),
    );
  },
  deleteItemProperties: (
    dispatch: Dispatch<any>,
    properties: any,
    propertyPaths: string[],
  ) => {
    // unset all of the propertyPaths
    const updates: Record<string, unknown> = {};
    propertyPaths.forEach((path) => {
      // split on . or [
      const topLevelPath = path.split(/[.\[\]]/)[0];
      updates[topLevelPath] = fastClone(get(properties, topLevelPath));
      if (path !== topLevelPath) {
        unset(updates, path);
      } else {
        updates[path] = null;
      }
    });
    const updatesWithBindings = mergeUpdatesWithBindingsOrTriggers(
      properties,
      getItemPropertyPaneConfig(properties.type),
      updates,
      true,
    );
    dispatch(updateAiChanges({ updates: updatesWithBindings, properties }));
  },
  deleteItem: (dispatch, widgetId, _itemScope, _deleteAllSelected) => {
    dispatch(removeChildInAiChanges({ widgetId }));
  },
  icon: () => null,
};
